import React, { useState, useEffect } from "react";
import api from "../../../services/api";
import { getCookie } from "../../../services/cookie";

import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PhoneIcon from "@material-ui/icons/Phone";
import MaskedInput from "react-text-mask";

import LocationOnIcon from "@material-ui/icons/LocationOn";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";

import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

import { useAuthorization } from "../../context/Authorization";
import { useSnackbar } from "../SnackbarProvider";
import { required, minValue } from "../../../utils/validate";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-prevent-tabpanel-${index}`}
      aria-labelledby={`scrollable-prevent-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-prevent-tab-${index}`,
    "aria-controls": `scrollable-prevent-tabpanel-${index}`,
  };
}

function TextMaskCEP(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/[0-9]/, /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/]}
      placeholderChar={"\u2000"}
    />
  );
}

function TextMaskTelFixo(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/[0-9]/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={"\u2000"}
    />
  );
}

function TextMaskTelCel(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        "(",
        /[0-9]/,
        /\d/,
        ")",
        "9",
        " ",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        "-",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      placeholderChar={"\u2000"}
    />
  );
}

TextMaskCEP.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  buttonUpdate: {
    marginTop: 20,
  },
}));

export default function TabsUpdateAssociete() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const { associete } = useAuthorization();
  const [name, setName] = useState("");
  const [cpf, setCpf] = useState("");
  const [matricula, setMatricula] = useState("");
  const [cep, setCep] = useState("");
  const [dtnascimento, setDtnascimento] = useState("");
  const [endereco, setEndereco] = useState("");
  const [bairro, setBairro] = useState("");
  const [cidade, setCidade] = useState("");
  const [uf, setUf] = useState("");
  const [telFixo, setTelfixo] = useState("");
  const [telCel, setTelCel] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingForm, setLoadingForm] = useState(false);

  const snackbar = useSnackbar();

  const token = getCookie("x-token");
  useEffect(() => {
    setLoadingForm(true)
    api
      .get("associete", { headers: { "x-client-token": token } })
      .then((response) => {
        const { data } = response.data;

        setName(data.name);
        setCpf(data.cpf);
        setMatricula(data.matricula);
        setDtnascimento(data.dtnascimento);
        setCep(data.cep);
        setEndereco(data.endereco);
        setCidade(data.cidade);
        setBairro(data.bairro);
        setUf(data.uf);
        setTelfixo(data.telfixo);
        setTelCel(data.telcel);
      }).catch(error => {
        alert('Erro interno! Tente novamente');
      }).finally(() => {
        setLoadingForm(false)
      });
  }, []);

  const handleUpdate = () => {
    setLoading(true);
    const data = {
      cep,
      endereco,
      bairro,
      cidade,
      uf,
      telFixo,
      telCel,
    };
    api
      .post("associete/update", data, {
        headers: { "x-client-token": token },
      })
      .then((response) => {
        const { data } = response.data;
        snackbar.showSuccessMessage(data ?? "Item atualizado com sucesso!");
         
      })
      .catch((error) => {
        const { erros } = error.response.data;
        snackbar.showFailureMessage(
          erros[0] ?? "Error ao atualizar, tente novamente!"
        );
      })
      .finally((response) => {
        setLoading(false);
      });
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleKeyUp = (event) => {
   // console.log(event, "keyup");
  };
  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="off"
          aria-label="scrollable prevent tabs example"
        >
          <Tab
            icon={<AccountCircleIcon />}
            aria-label="phone"
            {...a11yProps(0)}
          />
          <Tab
            icon={<LocationOnIcon />}
            aria-label="favorite"
            {...a11yProps(1)}
          />
          <Tab icon={<PhoneIcon />} aria-label="favorite" {...a11yProps(2)} />
        </Tabs>
      </AppBar>
      {loadingForm ? <CircularProgress  style={{marginTop:20, marginLeft: 20}}/> : 
      <TabPanel value={value} index={0}>
        <Typography variant="body2" component="span" color="primary">
          * Dados sensíveis só poderão ser atualizados com documentos
          comprobatórios, entre em contato com associação.
        </Typography>
        <TextField
          margin="normal"
          fullWidth
          disabled
          id="name"
          label="Nome"
          value={name}
          defaultValue={name}
        />
        <TextField
          margin="normal"
          fullWidth
          disabled
          id="cpf"
          label="CPF"
          value={cpf}
          defaultValue={cpf}
        />
        <TextField
          margin="normal"
          fullWidth
          disabled
          id="matricula"
          label="Matricula"
          value={matricula}
          defaultValue={matricula}
        />
        <TextField
          margin="normal"
          fullWidth
          disabled
          id="dtnascimento"
          label="Data de nascimento"
          value={dtnascimento}
          defaultValue={dtnascimento}
        />
      </TabPanel>
      }
      <TabPanel value={value} index={1}>
        <TextField
          error={required(cep)}
          helperText={required(cep)}
          required
          margin="normal"
          fullWidth
          id="cep"
          label="CEP"
          value={cep}
          onChange={(event) => setCep(event.target.value)}
          placeholder="59000-000"
          InputProps={{
            inputComponent: TextMaskCEP,
          }}
        />
        <TextField
          error={required(endereco)}
          helperText={required(endereco)}
          required
          margin="normal"
          fullWidth
          id="endereco"
          label="Endereço"
          value={endereco}
          onChange={(event) => setEndereco(event.target.value)}
          placeholder="Ex.: Rua maria, 10"
        />
        <TextField
          error={required(bairro)}
          helperText={required(bairro)}
          required
          margin="normal"
          fullWidth
          id="bairro"
          label="Bairro"
          value={bairro}
          onChange={(event) => setBairro(event.target.value)}
          placeholder=""
        />
        <TextField
          error={required(cidade)}
          helperText={required(cidade)}
          required
          margin="normal"
          fullWidth
          id="cidade"
          label="Cidade"
          value={cidade}
          onChange={(event) => setCidade(event.target.value)}
          placeholder=""
        />
        <TextField
          error={required(uf)}
          helperText={required(uf)}
          required
          margin="normal"
          fullWidth
          id="uf"
          label="UF"
          value={uf.toUpperCase()}
          onChange={(event) => setUf(event.target.value)}
          placeholder=""
          inputProps={{ maxLength: 2 }}
        />

        <Button
          disabled={loading}
          className={classes.buttonUpdate}
          variant="outlined"
          color="primary"
          onClick={handleUpdate}
        >
          {loading && <CircularProgress size={20} />}
          Atualizar
        </Button>
      </TabPanel>

      <TabPanel value={value} index={2}>
        <TextField
          margin="normal"
          fullWidth
          id="telfixo"
          label="Telefone Fixo"
          value={telFixo}
          onChange={(event) => setTelfixo(event.target.value)}
          placeholder="9999-9999"
          InputProps={{
            inputComponent: TextMaskTelFixo,
          }}
        />
        <TextField
          margin="normal"
          fullWidth
          id="telcel"
          label="Telefone Celular"
          value={telCel}
          onChange={(event) => setTelCel(event.target.value)}
          placeholder="(84)9 9999-9999"
          InputProps={{
            inputComponent: TextMaskTelCel,
          }}
        />

        <Button
          disabled={loading}
          className={classes.buttonUpdate}
          variant="outlined"
          color="primary"
          onClick={handleUpdate}
        >
          {loading && <CircularProgress size={20} />}
          Atualizar
        </Button>
      </TabPanel>
    </div>
  );
}
