import React from "react";
import Routes from "./routes";
import "./global.css";
import SimpleSnackBarProvider from "./components/context/SimpleSnackBar";
import HandleButtonHeaderMenu from "./components/context/HandleButtonHeaderMenu";
import {
  StylesProvider,
  ThemeProvider as MuiThemeProvider,
} from "@material-ui/styles";
import theme from "../src/theme";
import { ThemeProvider } from "styled-components";
import ContentProvider from "./components/context/Content";
import AuthorizationProvider from "./components/context/Authorization";

import { SnackbarProvider } from "./components/layout/SnackbarProvider";
function App() {
  return (
    <StylesProvider injectFirst>
      <MuiThemeProvider
        theme={{
          ...theme,
          // props: {
          //   // Change the default options of useMediaQuery
          //   MuiUseMediaQuery: { ssrMatchMedia: this.ssrMatchMedia },
          // },
        }}
      >
        <ThemeProvider theme={theme}>
          <AuthorizationProvider>
            <HandleButtonHeaderMenu>
              <ContentProvider>
                <SnackbarProvider>
                  <SimpleSnackBarProvider>
                    <Routes />
                  </SimpleSnackBarProvider>
                </SnackbarProvider>
              </ContentProvider>
            </HandleButtonHeaderMenu>
          </AuthorizationProvider>
        </ThemeProvider>
      </MuiThemeProvider>
    </StylesProvider>
  );
}

export default App;
