import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Fade from "@material-ui/core/Fade";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
}));

export default function AccordionHelper({ item }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {item.map((value) => (
        <Fade in={true} timeout={500}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="h4" component="b" color="primary">
                {value.title}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                dangerouslySetInnerHTML={{ __html: value.description }}
              ></Typography>
            </AccordionDetails>
          </Accordion>
        </Fade>
      ))}
    </div>
  );
}
