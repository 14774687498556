import React, { createContext, useState, useContext} from 'react';

const ContentContext = createContext();

export default function ContentProvider({ children }){
    
    const [typeContent, setTypeContent] = useState('');

    return (
        <ContentContext.Provider value={
            { typeContent, setTypeContent }
        }>
            {children}
        </ContentContext.Provider>
    )
}

export function useContent() { 
    const context = useContext(ContentContext);

    const { typeContent, setTypeContent } = context; 

    return { typeContent, setTypeContent };   
}