import React, { useState } from "react";
import { validateEmail } from "../../utils/validate";

import { auth } from "../../services/firebase";

import { useHistory } from "react-router-dom";

import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Divider from "@material-ui/core/Divider";

import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";

import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Card from "@material-ui/core/Card";
import CircularProgress from "@material-ui/core/CircularProgress";

import AppBar from "../../components/layout/AppBar";
import { useSnackbar } from "../../components/layout/SnackbarProvider";

import Logo from "../../assets/logo_transparent.png";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://assemarn.com.br/">
        ASSEMA/RN
      </Link>{" "}
      1978 -{new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#e6e6e6",
    backgroundSize: "cover",
    width: "100%",
    left: 0,
    height: "40px",
    position: "relative",
  },
  card: {
    padding: theme.spacing(3),
    borderRadius: 5,
    display: "flex",
    marginTop: "5%",
  },

  paper: {
    marginTop: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    padding: 20,
    marginRight: 20,
    marginLeft: 20,
  },
  submit: {
    backgroundColor: "#1a4838",
    "&:hover": {
      backgroundColor: "#1a4838",
      opacity: "80%",
    },
  },
  logo: {
    maxWidth: " 150px",
    maxHeight: "100px",
  },
  wrapper: {
    position: "relative",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  linkLogin: {
    display: "flex",
    textAlignLast: "center",
  },
}));

export default function ForgotPassword() {
  const classes = useStyles();
  const history = useHistory();
  const snackbar = useSnackbar();
  const [email, setEmail] = useState("");
  const [loadingProcess, setLoadingProcess] = useState(false);

  async function HandleLogin(event) {
    event.preventDefault();
    setLoadingProcess(true);
    const data = {
      login: email,
    };
    auth
      .setPersistence("local")
      .then(() => {
        auth
          .sendPasswordResetEmail(data.login)
          .then((response) => {
            snackbar.showSuccessMessage(`Se houver um cadastro com este e-mail, 
            enviaremos um e-mail com instruções para a recuperação da senha. Verifique a caixa de spam `);
            history.push("/");
          })
          .catch((error) => {
            const errorMessage =
              error.message ?? "Erro ao tentar redefinir senha!";
            snackbar.showFailureMessage(errorMessage);
          })
          .finally((data) => {
            setLoadingProcess(false);
          });
      })
      .catch((error) => {
        snackbar.showFailureMessage("Erro ao tentar redefinir senha!");
        setLoadingProcess(false);
      });
  }

  return (
    <React.Fragment>
      <div className={classes.root}>
        <AppBar />

        <Container component="main" maxWidth="xs">
          <Grid container>
            <Card className={classes.card} id="card">
              <CssBaseline />
              <div className={classes.paper}>
                <img
                  className={classes.logo}
                  src={Logo}
                  alt=""
                  sizes=""
                  srcset=""
                />

                <Typography
                  style={{ fontWeight: "bold" }}
                  variant="h4"
                  component="h4"
                  color="primary"
                >
                  Esqueci minha senha
                </Typography>

                <Grid
                  item
                  container
                  direction="column"
                  justify="center"
                  alignItems="center"
                ></Grid>
                <form className={classes.form} onSubmit={HandleLogin}>
                  <TextField
                    type="email"
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                    error={validateEmail(email) && email.length > 0}
                    helperText={
                      validateEmail(email) && email.length > 0
                        ? validateEmail(email)
                        : ""
                    }
                    focused={email.length > 0}
                  />

                  <div className={classes.wrapper}>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      disabled={
                        typeof validateEmail(email) !== "undefined" ||
                        loadingProcess
                      }
                    >
                      Entrar
                    </Button>
                    {loadingProcess && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                  </div>
                  <Divider style={{ marginTop: 20, marginBottom: 10 }} />
                  <Grid container className={classes.linkLogin}>
                    <Grid item xs>
                      <Link
                        onClick={() => {
                          history.push("/");
                        }}
                        component="button"
                        color="primary"
                        variant="body2"
                      >
                        Fazer login
                      </Link>
                    </Grid>
                  </Grid>
                </form>
              </div>
            </Card>
            <Box mt={2}>
              <Copyright />
            </Box>
          </Grid>
        </Container>
      </div>
    </React.Fragment>
  );
}
