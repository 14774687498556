import React, { useEffect } from 'react';

import NavBar from '../../components/layout/MenuNav/Dashboard';
import { useContent } from '../../components/context/Content';

export default function Assembly(){

    const { typeContent, setTypeContent } = useContent();

    useEffect(() => {
        setTypeContent('assembly');
    },[]);
     
    return(
 
        <NavBar />
 
         
    );
}