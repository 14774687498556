import React, { useState, useEffect } from "react";
import api from "../../../../services/api";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import TextField from "@material-ui/core/TextField";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import CircularProgress from "@material-ui/core/CircularProgress";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import Fade from "@material-ui/core/Fade";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import PhoneIcon from "@material-ui/icons/Phone";
import LocationOnIcon from "@material-ui/icons/LocationOn";

import SpeakerNotes from "@material-ui/icons/SpeakerNotes";

import DocumentListItem from "../../DocumentListItem";
import { getCookie } from "../../../../services/cookie";

import { useAuthorization } from "../../../context/Authorization";

import { useSnackbar } from "../../SnackbarProvider";
import { useHistory } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginBottom: 20,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "rigth",
  },
  grid: {
    marginTop: 10,
    marginBottom: 20,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  form: {
    display: "grid",
    margin: theme.spacing(1),
  },
  loading: {
    marginTop: 10,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export default function ContentsAgreement() {
  const classes = useStyles();
  const token = getCookie("x-token");
  const [itemAgreement, setItemAgreement] = useState([]);
  const [open, setOpen] = useState(false);
  const [dataItem, setDataItem] = useState({});

  const handleClickOpen = (value) => {
    setOpen(true);
    setDataItem(value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [search, setSearch] = useState("");

  const [loading, setLoading] = useState(false);

  const snackbar = useSnackbar();
  const history = useHistory();

  const { associete } = useAuthorization();

  // useEffect(() => {
  //   if (!associete) {
  //     snackbar.showFailureMessage("Úsuario não autorizado!");
  //     history.push("/home");
  //   }
  // }, []);

  useEffect(() => {
    setLoading(true);
    api
      .get("convenio/list", {
        params: {
          search,
        },
        headers: {
          "x-client-token": token,
        },
      })
      .then((response) => {
        const { data } = response.data;
        console.log(data)
        setItemAgreement(data);
      })
      .catch((error) => {
        snackbar.showFailureMessage(
          "Error ao carregar os dados, recarrege a pagina!"
        );
 
      })
      .finally((response) => {
        setLoading(false);
      });
  }, [search]);

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid className={classes.grid} item xs={12}>
          <Fade in={true} timeout={500}>
            <Paper className={classes.paper}>
              <Typography
                component="strong"
                variant="h3"
                color="inherit"
                noWrap
              >
                Convênios
              </Typography>

              {loading ? (
                <div className={classes.loading}>
                  <CircularProgress />
                </div>
              ) : itemAgreement.length > 0 ? (
                <List dense={true}>
                  {itemAgreement.map((value) => (
                    <ListItem button onClick={() => handleClickOpen(value)}>
                      <ListItemIcon>
                        <SpeakerNotes />
                      </ListItemIcon>
                      <ListItemText
                        primary={value?.company.name}
                        secondary={value?.company?.category?.name}
                      />
                    </ListItem>
                  ))}
                </List>
              ) : (
                <Typography>Sem dados!</Typography>
              )}
            </Paper>
          </Fade>
        </Grid>
      </Grid>

      <Dialog
        open={open}
        onClose={handleClose}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogTitle id="scroll-dialog-title">
          {dataItem.company?.name}
        </DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
            <Typography color="primary" variant="p" component="b">
              Descrição
            </Typography>
            <Typography color="primary" variant="p" component="p">
              {dataItem?.description}
            </Typography>
            {dataItem.company?.contact && (
              <Accordion style={{ marginTop: 15 }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.heading}>Contato</Typography>
                </AccordionSummary>
                <AccordionDetails style={{ display: "grid" }}>
                  <Grid>
                    <PhoneIcon style={{ marginRight: 10 }} />
                    <Typography
                      color="primary"
                      component="a"
                      variant="p"
                      href={`tel:${dataItem.company?.contact}`}
                    >
                      {dataItem.company?.contact}
                    </Typography>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            )}
            {dataItem.company?.zipcode && (
              <Accordion style={{ marginTop: 15 }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.heading}>Endereço</Typography>
                </AccordionSummary>
                <AccordionDetails style={{ display: "grid" }}>
                  <Grid>
                    <Typography style={{ marginBottom: 10 }}>
                      <LocationOnIcon style={{ marginRight: 10 }} />
                      {`${dataItem.company?.address} - ${dataItem.company?.district}  - ${dataItem.company?.city}  - ${dataItem.company?.state}  - ${dataItem.company?.zipcode}`}
                    </Typography>
                  </Grid>
                  {dataItem.company?.maps && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: dataItem.company?.maps,
                      }}
                    ></div>
                  )}
                </AccordionDetails>
              </Accordion>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained" color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
