import React, { useEffect, useState } from "react";
import api from "../../services/api";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Fade from "@material-ui/core/Fade";
import Img from "../../assets/ilustra/Business_PNG.png";
import Header from "../../components/layout/Header";
import Menu from "../../components/layout/Menu";
import Content from "../../components/layout/Page/Content";
import WalletCard from "../../components/layout/WalletCard";
import UnregisteredAssociate from "../../components/layout/UnregisteredAssociate";
import { useAuthorization } from "../../components/context/Authorization";
import { getCookie } from "../../services/cookie";
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexGrow: 1,
  },
  card: {
    backgroundColor: '#0f4509',
    top: '50%',
    left: '15%',
    padding:'20px',
    borderRadius: '20px',
    width:'100%',
    height: '250px',
  },
}));

export default function WalletAssociate() {
  const classes = useStyles();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  const token = getCookie("x-token");
  useEffect(() => {
    setLoading(true)
    api
      .get("associete", { headers: { "x-client-token": token } })
      .then((response) => {
        const { data } = response.data;
 
        setData(data)
      }).finally((response) => {
        setLoading(false)
      });
  }, []);


  return (
    <div className={classes.root}>
      <Header />
      <Menu />
      <Content>
        <Typography variant="h1" component="h1"> Carterinha Virtual</Typography>
        {!loading ?  <WalletCard 
            name={data.name}
            matricula={data.matricula}
            dtnascimento={data.dtnascimento}
            situacao={data.situacao}
        /> :
            <Skeleton variant="rect" width={210} height={118} />
        }
       
        
      </Content>
    </div>
  );
}
