import React, { createContext, useState, useContext, useEffect } from "react";
import api from "../../services/api";
import { getCookie } from "../../services/cookie";

const AuthorizationContext = createContext();

export default function Authorization({ children }) {
  const [authorization, setAuthorization] = useState(false);
  const [associete, setAssociete] = useState(false);
  const [loading, setLoading] = useState(true);
  const [name, setName] = useState("USER");
  const [lastname, setLastname] = useState("");
  const [cpf, setCpf] = useState("");
  const [email, setEmail] = useState("");

  useEffect(() => {
    const token = getCookie("x-token");

    if (token) {
      setAuthorization(true);

      api
        .get("auth/client", {
          headers: {
            "x-client-token": token,
          },
        })
        .then((response) => {
          const { data } = response.data;
          const name = data.name;
          const lastname = data.lastname;
          const cpf = data.cpf;
          const email = data.email;
          const associate = data.associete ?? false;
        
          setName(name);
          setLastname(lastname);
          setAssociete(associate);
          setCpf(cpf);
          setEmail(email);
        })
        .catch((error) => {
          alert('Erro interno! Tente novamente');
          localStorage.clear();
          //window.location.assign("/");
        })
        .finally((response) => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, []);

  if (loading) {
    return <h1>Loading...</h1>;
  }

  return (
    <AuthorizationContext.Provider
      value={{
        authorization,
        setAuthorization,
        name,
        setName,
        associete,
        setAssociete,
        lastname,
        email,
        cpf,
      }}
    >
      {children}
    </AuthorizationContext.Provider>
  );
}

export function useAuthorization() {
  const context = useContext(AuthorizationContext);

  const {
    authorization,
    setAuthorization,
    name,
    setName,
    associete,
    setAssociete,
    lastname,
    email,
    cpf,
  } = context;

  return {
    authorization,
    setAuthorization,
    name,
    setName,
    associete,
    setAssociete,
    lastname,
    email,
    cpf,
  };
}
