import React, { useState, useEffect } from "react";

import { useHistory } from "react-router-dom";
import api from "../../services/api";

import Grid from "@material-ui/core/Grid";

import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import CircularProgress from "@material-ui/core/CircularProgress";
import Card from "@material-ui/core/Card";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import AppBar from "../../components/layout/AppBar";
import AccordionHelper from "../../components/layout/AccordionHelper";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#e6e6e6",
    backgroundSize: "cover",
    width: "100%",
    left: 0,
    height: "40px",
  },
  card: {
    width: "100%",

    marginBottom: 20,
    padding: 10,
    backgroundColor: "#4db6ac",
  },
  title: {
    display: "flex",
    alignItems: "center",
    color: "white",
    marginLeft: 20,
    marginTop: 10,
    marginBottom: 10,
  },
}));

export default function Help() {
  const classes = useStyles();
  const [itemData, setItemData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    api
      .get("common-question")
      .then((response) => {
        const { data } = response.data;
        setItemData(data);
      })
      .finally((response) => {
        setLoading(false);
      });
  }, []);

  return (
    <React.Fragment>
      <AppBar />

      <Grid>
        <Card className={classes.card} id="card">
          <Typography variant="h3" component="b" className={classes.title}>
            <HelpOutlineIcon fontSize="large" style={{ marginRight: 20 }} />{" "}
            Perguntas Frequentes
          </Typography>
        </Card>
        <Container component="main" maxWidth="md">
          <Grid container>
            <AccordionHelper item={itemData} />
            {loading && <CircularProgress />}
            {!itemData.length > 0 && (
              <Typography variant="h3"> Sem perguntas cadastradas</Typography>
            )}
          </Grid>
        </Container>
      </Grid>
    </React.Fragment>
  );
}
