import React, { useState, useEffect } from "react";
import firebase from "firebase/compat/app";
import api from "../../services/api";
import { validateEmail, validatePassword } from "../../utils/validate";

import { auth } from "../../services/firebase";
import { useAuthorization } from "../../components/context/Authorization";
import { getCookie, setCookie, removeCookie } from "../../services/cookie";
import SvgIcon from "@material-ui/core/SvgIcon";

import { useHistory } from "react-router-dom";
import "./styles.css";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";

import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Card from "@material-ui/core/Card";
import CircularProgress from "@material-ui/core/CircularProgress";

import AppBar from "../../components/layout/AppBar";
import SimpleSnackBar from "../../components/layout/SimpleSnackBar";
import { useSimpleSnackBar } from "../../components/context/SimpleSnackBar";
import { useSnackbar } from "../../components/layout/SnackbarProvider";
import { useTheme } from "@material-ui/core/styles";
import Logo from "../../assets/logo_transparent.png";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://assemarn.com.br/">
        ASSEMA/RN
      </Link>{" "}
      1978 -{new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#e6e6e6",
    backgroundSize: "cover",
    width: "100%",
    left: 0,
    height: "40px",
    position: "relative",
  },
  card: {
    padding: theme.spacing(3),
    borderRadius: 5,
    display: "flex",
    marginTop: "5%",
  },

  paper: {
    marginTop: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  form: {
    width: "90%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    backgroundColor: "#1a4838",
    "&:hover": {
      backgroundColor: "#1a4838",
      opacity: "80%",
    },
  },
  logo: {
    maxWidth: " 150px",
    maxHeight: "100px",
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

export default function Login() {
  const classes = useStyles();
  const history = useHistory();
  const snackbar = useSnackbar();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [titleSnack, setTitleSnack] = useState("");
  const [isAlreadyLogged, setIsAlreadyLogged] = useState(true);
  const [loadingProcess, setLoadingProcess] = useState(false);

  const { setAuthorization } = useAuthorization();
  const { opensnack, setOpenSnack } = useSimpleSnackBar(false);
  const theme = useTheme();
  const token = getCookie("x-token");

  useEffect(() => {
    if (token && isAlreadyLogged) {
      snackbar.showSuccessMessage("Usuário já autenticado");
      history.push("/home");
    }
  }, [token, isAlreadyLogged]);

  useEffect(() => {
    if (token && isAlreadyLogged) {
    }
  }, [email, password]);

  function GmailIcon(props) {
    return (
      <SvgIcon {...props} style={{ color: "white" }}>
        <path d="M16.5786 2.47078C15.589 3.46036 14.6382 4.41113 13.6778 5.3716C12.4165 4.39173 10.9807 3.97455 9.35078 4.12008C6.42085 4.39173 4.02452 7.07911 4.11184 10.0284C4.19915 13.0263 6.1298 15.3547 9.08883 15.8204C11.427 16.1891 13.8427 15.4129 14.9293 13.327C15.1233 12.9487 15.2591 12.5315 15.4629 12.027C14.4733 12.027 13.5904 12.027 12.7173 12.027C11.8538 12.027 10.9807 12.027 10.0881 12.027C10.0881 10.727 10.0881 9.49485 10.0881 8.22392C13.1733 8.22392 16.2487 8.22392 19.363 8.22392C19.819 10.2031 19.6152 12.1434 18.8779 14.0062C17.4517 17.6055 14.7449 19.575 10.8934 19.9533C5.81934 20.4481 1.24012 16.9555 0.211735 12.0173C-0.952474 6.50671 2.8118 0.986416 8.3709 0.132663C11.2135 -0.303916 13.7845 0.336399 16.0741 2.06331C16.2487 2.18943 16.4039 2.32526 16.5786 2.47078Z" />
      </SvgIcon>
    );
  }

  async function HandleGmail(event) {
    event.preventDefault();

    let provider = new firebase.auth.GoogleAuthProvider();
    signInWithFirebase(provider);
  }

  function signInWithFirebase(provider) {
    setIsAlreadyLogged(false);
    auth.languageCode = "pt";
    auth
      .signInWithPopup(provider)
      .then(async (result) => {
        setLoadingProcess(true);

        const { user } = result;

        const tokenU = await user.getIdToken();
        const uid = user.uid;

        try {
          const {
            data: { sucesso },
          } = await api.get("auth/client", {
            headers: {
              "x-client-token": tokenU,
            },
          });

          if (sucesso) {
            snackbar.showSuccessMessage("Redirecionando usuário...");
            setCookie("x-token", tokenU);
            setAuthorization(true);
            window.location.href = "/home";
            //history.push("/home");
          }
        } catch (err) {
          await api.post("auth/user/delete", { id: uid });

        
          setLoadingProcess(false);
          if (err.response && err.response.status === 406) {
            // history.push("/sign-up");
            snackbar.showFailureMessage(
              "O email informado não está cadastrado."
            );
          } else {
            throw new Error();
          }
        }
      })
      .catch((error) => {
        // snackbar.showFailureMessage('Não foi possível realizar a autenticação');
      });
  }

  async function HandleLogin(event) {
    event.preventDefault();
    setIsAlreadyLogged(false);
    setLoadingProcess(true);
    const data = {
      login: email,
      password,
    };
    auth
      .setPersistence("local")
      .then(() => {
        auth
          .signInWithEmailAndPassword(data.login, data.password)
          .then((response) => {
            const tokenFirebase = response.user.multiFactor.user.accessToken;
            setCookie("x-token", tokenFirebase);
            setAuthorization(true);
            window.location.href = "/home";
            // history.push("/home");
          })
          .catch((error) => {
           
            // Handle Errors here.
            var errorCode = error.code;
            var errorMessage = error.message;
            snackbar.showFailureMessage(errorMessage);

            //callback({ [FORM_ERROR]: "Credenciais inválidas" });
            if (errorCode == "auth/user-not-found") {
              snackbar.showFailureMessage(
                "O email informado não está cadastrado."
              );

              setTitleSnack("O email informado não está cadastrado.");
            } else if (errorCode == "auth/wrong-password") {
              snackbar.showFailureMessage("A senha informada não corresponde.");
            }
          })
          .finally((data) => {
            setLoadingProcess(false);
          });
      })
      .catch((error) => {
        snackbar.showFailureMessage("Não foi possível realizar a autenticação");
        setLoadingProcess(false);
      });

    // await api
    //   .post("auth/login", data)
    //   .then((response) => {
    //     const token = response.data.access_token;
    //     localStorage.setItem("token", token);
    //     setAuthorization(true);
    //     history.push("/home");
    //     setOpenSnack(false);
    //   })
    //   .catch((error) => {
    //     setTitleSnack(
    //       error.response
    //         ? error.response.request.statusText
    //         : "Internal Server Error"
    //     );
    //     setOpenSnack(true);
    //   });
  }

  return (
    <React.Fragment>
      <div className={classes.root}>
        <AppBar />

        <Container component="main" maxWidth="xs">
          <Card className={classes.card} id="card">
            <CssBaseline />
            <div className={classes.paper}>
              <img
                className={classes.logo}
                src={Logo}
                alt=""
                sizes=""
                srcset=""
              />

              <Typography
                style={{ fontWeight: "bold" }}
                variant="h4"
                component="h4"
                color="primary"
              >
                Login
              </Typography>
              <Button
                variant="contained"
                style={{
                  color: "white",
                  backgroundColor: theme.palette.google.main,
                  marginTop: "10px",
                }}
                onClick={HandleGmail}
                startIcon={<GmailIcon />}
              >
                Continuar com google
              </Button>
              <Grid
                item
                container
                direction="column"
                justify="center"
                alignItems="center"
              >
                {" "}
                <Typography
                  className="title-with-lines"
                  variant="h6"
                  component="h5"
                  style={{ marginTop: 5, marginBottom: -3 }}
                >
                  <span>ou </span>{" "}
                </Typography>{" "}
              </Grid>
              <form className={classes.form} onSubmit={HandleLogin}>
                <TextField
                  type="email"
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                  error={validateEmail(email) && email.length > 0}
                  helperText={
                    validateEmail(email) && email.length > 0
                      ? validateEmail(email)
                      : ""
                  }
                  focused={email.length > 0}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  value={password}
                  onChange={(event) => setPassword(event.target.value)}
                  error={validatePassword(password) && password.length > 0}
                  helperText={
                    validatePassword(password) && password.length > 0
                      ? validatePassword(password)
                      : ""
                  }
                  focused={password.length > 0}
                />
                <FormControlLabel
                  control={<Checkbox value="remember" color="primary" />}
                  label="Remember me"
                />
                <div className={classes.wrapper}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    disabled={
                      typeof validateEmail(email) !== "undefined" ||
                      typeof validatePassword(password) !== "undefined" ||
                      loadingProcess
                    }
                  >
                    Entrar
                  </Button>
                  {loadingProcess && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </div>
                <Grid container>
                  <Grid item xs>
                    <Link
                      onClick={() => {
                        history.push("/forgot-password");
                      }}
                      component="button"
                      color="primary"
                      variant="body2"
                    >
                      Esqueceu a senha?
                    </Link>
                  </Grid>
                  <Grid item xs>
                    <Link
                      component="button"
                      color="primary"
                      variant="body2"
                      onClick={() => {
                        history.push("/sign-up");
                      }}
                    >
                      Ainda não tem conta? Registre-se
                    </Link>
                  </Grid>
                </Grid>
              </form>
            </div>
          </Card>
          <Box mt={2}>
            <Copyright />
          </Box>
        </Container>
        <SimpleSnackBar title={titleSnack} />
      </div>
    </React.Fragment>
  );
}
