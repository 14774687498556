import { createMuiTheme } from "@material-ui/core/styles";
import { red, green } from "@material-ui/core/colors";

// Create a theme instance.
const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#194b3b",
      light: "#894C9E",
      dark: "#194b3bc2",
      contrastText: "#fff",
    },
    secondary: {
      main: "#FDBA63",
      light: "#fdc57c",
      dark: "#E5AA5A",
      contrastText: "#fff",
    },
    facebook: {
      main: "#3B5998",
      dark: "#192E6A",
    },
    google: {
      main: "#DC4E41",
      dark: "#CE352A",
    },
    accent: {
      main: "#3EC2CF",
      light: "#61C9D4",
      dark: "#37B1BD",
      contrastText: "#fff",
    },
    action: {
      main: "#3EC2CF",
      contrastText: "#fff",
    },
    error: {
      main: "#E96F6F",
    },
    success: {
      main: "#6ECF95",
    },
    text: {
      primary: "#828282",
      secondary: "#BDBDBD",
    },
    background: {
      default: "#FAFAFA",
      border: "#E0E0E0",
      primary: "#F2F2F2",
    },
    white: {
      default: "#FFFFFF",
    },
    orange: {
      default: "#FFB350",
      light: "#FFC272",
      dark: "#CC8F40",
    },
    blue: {
      default: "#2d9cdb",
    },
    poa: {
      main: "#337AB7",
      dark: "#333333",
      default: "#195a92",
      hoverBlack: "#333333bd",
    },
  },
  typography: {
    fontFamily: ["Arial", "sans-serif"].join(", "),
    fontWeightBold: 800,
    h1: {
      fontFamily: ["Arial", "sans-serif"].join(", "),
      fontWeight: "normal",
      fontSize: "40px",
      lineHeight: "55px",
    },
    h2: {
      fontFamily: ["Arial", "sans-serif"].join(", "),
      fontWeight: "normal",
      fontSize: "36px",
      lineHeight: "49px",
    },
    h3: {
      fontFamily: ["Helvetica", "Arial", "sans-serif"].join(", "),
      fontWeight: "normal",
      fontSize: "28px",
      lineHeight: "38px",
    },
    h4: {
      fontFamily: ["Arial", "sans-serif"].join(", "),
      fontWeight: "normal",
      fontSize: "24px",
      lineHeight: "30px",
    },
    h5: {
      fontFamily: ["Arial", "sans-serif"].join(", "),
      fontWeight: "normal",
      fontSize: "18px",
      lineHeight: "25px",
    },
    h6: {
      fontFamily: ["Arial", "sans-serif"].join(", "),
      fontWeight: "normal",
      fontSize: "14px",
      lineHeight: "19px",
    },
    body1: {
      fontFamily: ["Arial", "sans-serif"].join(", "),
      fontWeight: "normal",
      fontSize: "16px",
      lineHeight: "24px",
    },
    caption: {
      fontFamily: ["Arial", "sans-serif"].join(", "),
      fontWeight: "normal",
      fontSize: "12px",
      lineHeight: "18px",
    },
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: 14,
      },
    },
  },
});

export default theme;
