import React, { useEffect, useState } from "react";
import api from "../../../services/api";
import { useHistory, Link } from "react-router-dom";
import { useAuthorization } from "../../context/Authorization";

import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import Divider from "@material-ui/core/Divider";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import AccountCircle from "@material-ui/icons/AccountCircle";
import { useButtonMenu } from "../../context/HandleButtonHeaderMenu";
import clsx from "clsx";
import { removeCookie, getCookie } from "../../../services/cookie";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";

import BadgeHeaderComunicate from "../BadgeHeaderComunicate";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  iconTitle: {
    marginRight: 5,
    fontSize: 20,
  },
}));

export default function Header(props) {
  const classes = useStyles();
  const history = useHistory();
  const { name, associete } = useAuthorization();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [itemBadge, setItemBadge] = useState([]);
  const open = Boolean(anchorEl);

  const token = getCookie("x-token");

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const { openButtonMenu, setOpenButtonMenu } = useButtonMenu();

  const handleDrawerOpen = () => {
    setOpenButtonMenu(true);
  };

  const handleExit = () => {
    removeCookie("x-token");
    localStorage.clear();
    history.push("/");
  };

  useEffect(() => {
    api
      .get("comunicate", {
        headers: {
          "x-client-token": token,
        },
      })
      .then((response) => {
        const { data } = response.data;
        setItemBadge(data);
      });
  }, []);

  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar
        position="absolute"
        style={{ backgroundColor: "#4db6ac" }}
        className={clsx(classes.appBar, openButtonMenu && classes.appBarShift)}
      >
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(
              classes.menuButton,

              openButtonMenu && classes.menuButtonHidden
            )}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          >
            ASSEMA/RN
          </Typography>

          {associete && <BadgeHeaderComunicate item={itemBadge} />}

          <Button
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
          >
            <AccountCircle color="inherit" />
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              className={classes.title}
            >
              {name}
            </Typography>
          </Button>
          <div>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={open}
              onClose={handleClose}
            >
              <Link
                to="/profile"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <MenuItem>
                  <PersonOutlineIcon className={classes.iconTitle} />
                  Perfil
                </MenuItem>
              </Link>

              <Link
                to="/change-password"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <MenuItem>
                  <LockOutlinedIcon className={classes.iconTitle} />
                  Alterar Senha
                </MenuItem>
              </Link>

              <Divider />
              <MenuItem onClick={handleExit}>
                {" "}
                <ExitToAppIcon className={classes.iconTitle} />
                Exit
              </MenuItem>
            </Menu>
          </div>

          {/* <IconButton onClick={handleExit} color="inherit">
            <Badge color="secondary">
              <ExitToAppIcon />
            </Badge>
          </IconButton> */}
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
}
