import React, { useEffect, useState } from "react";
import Logo from "../../../assets/new_imagem/logo_transparent.png";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
 
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexGrow: 1,
  },
  card: {
    backgroundImage: 'linear-gradient(#116609, #000000)',
    //backgroundColor: '#116609',
    top: '50%',
    left: '15%',
    padding:'20px',
    borderRadius: '20px',
    width: '100%',
    [theme.breakpoints.up("sm")]: {
        width: '350px',
    },
    height: '220px',
  },
  logo: {
    width:'120px',
    height: '80px',
    marginLeft: -5
  }
}));

export default function WalletCard({name, matricula, dtnascimento, situacao}) {
  const classes = useStyles();
 
  return (
    <>  
        <div className={classes.card}>
            <img className={classes.logo} src={Logo} />
            <Typography variant="p" component="p" color="secondary" style={{marginBottom: 10, marginTop:10}}> {name}</Typography>
            <Typography variant="p" component="p" color="textSecondary"> Matricula: {matricula} </Typography>
            <Typography variant="p" component="p" color="textSecondary"> Dt. Nasc.: {dtnascimento} </Typography>
            <Typography variant="p" component="p" color="textSecondary"> Situação: {situacao}</Typography>
        </div>
    </>
  );
}
