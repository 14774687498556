import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActionArea from "@material-ui/core/CardActionArea";
import Avatar from "@material-ui/core/Avatar";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexGrow: 1,
  },
  card: {
    display: "flex",
    padding: 20,
    marginBottom: 10,
  },
  paper: {
    color: theme.palette.text.secondary,
  },
  grid: {
    marginTop: 20,
    width: "100%",
  },
  avatarActive: {
    color: "#fff",
    backgroundColor: "#0080007a",
  },
}));

export default function CardItemUpdateAssociete({ item }) {
  const classes = useStyles();

  useEffect(() => {}, []);
  const history = useHistory();
  const handleClickHistory = (link) => {
    history.push(link);
  };

  return (
    <CardActionArea
      className={classes.paper}
      onClick={() => handleClickHistory(item.link)}
    >
      <Card>
        <CardHeader
          avatar={
            <Avatar
              aria-label="recipe"
              className={item.active && classes.avatarActive}
            >
              {item.icon ? item.icon : item.title.substr(0, 1)}
            </Avatar>
          }
        />
        <CardContent>
          <Typography variant="h4" color="primary" component="b">
            {item.title}
          </Typography>
        </CardContent>
      </Card>
    </CardActionArea>
  );
}
