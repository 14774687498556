import React from "react";

import { Link } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";

import Header from "../../components/layout/Header";
import Menu from "../../components/layout/Menu";
import Content from "../../components/layout/Page/Content";

import ChangePasswordForm from "../../components/layout/Page/ChangePasswordForm";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
}));

export default function ChangePassword() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Header />
      <Menu />
      <Content>
        <Breadcrumbs aria-label="breadcrumb">
          <Link style={{ textDecoration: "none", color: "inherit" }} to="/home">
            Dashboard
          </Link>
          <Typography color="textPrimary">Alterar Senha</Typography>
        </Breadcrumbs>
        <ChangePasswordForm />
      </Content>
    </div>
  );
}
