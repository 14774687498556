import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';
 
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import HomeIcon from '@material-ui/icons/Home';
import PeopleIcon from '@material-ui/icons/People';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import AssignmentIcon from '@material-ui/icons/Assignment';

import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';

import EventIcon from '@material-ui/icons/Event';
import LinkIcon from '@material-ui/icons/Link';
import ScheduleIcon from '@material-ui/icons/Schedule';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

import Icon from '@material-ui/core/Icon';
 
import api from '../../services/api';


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
     
  },
  link: {
    display: 'flex',
    textDecoration: 'none',
    color:"inherit",
    fontSize:14
  },
  breadcrumbs:{
     marginBottom:15,
     
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
    verticalAlign:'middle'
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'rigth',
    color: theme.palette.text.secondary,
    fontSize:18,
    marginBottom:10,
  },
  rounded: {
    marginRight: theme.spacing(0.5),
    backgroundColor: '#c14545',
   
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  card:{
      marginBottom:10
  },
  button: {
     
    marginBottom: 12,
    margin: theme.spacing(3, 0, 2),
     
  },
  buttonChat:{
    float:"right",
    marginRight:10
  }
}));

export default function AssemblyDetail() {
  const classes = useStyles();

  const [value, setValue] = useState('');
  const [scheduleResponse, setScheduleResponse] = useState([]);
  const [assembly, setAssembly] = useState([]);
  const [schedule, setSchedule] = useState([]);
 
  const token = localStorage.getItem('token');
  let { id } = useParams();

  const handleChange = (e,response) => {

    const itensCopy = Array.from(scheduleResponse);

 
    if ( itensCopy.length >= 2)
    {
      
        itensCopy.map((index) => 
        {

          const data = {id:response.id,value: e}

          if(index.id === response.id)
          {
            
            var numberPositionArray = itensCopy.map(function(e) { return e.id; }).indexOf(index.id);
            
            itensCopy.splice(numberPositionArray, 1, data);
            
          }
        }
 
      ) 

    }else{

      itensCopy.push({ id:response.id,value: e});
    
    }
    
    setValue(e);
    setScheduleResponse(itensCopy)
    
    
  };

  function handleResponse(){
    
    const idScheduleResponse = scheduleResponse.map((index) =>(index.value));
    const data = {
      'schedule_response_id': idScheduleResponse
    }

    api.post('schedule/response', data, { 
      headers:{
      'Authorization': 'Bearer ' +  token  
      }
    }).then(response => {
      console.log(response)
  });
    
    
  }

  useEffect(() => {

      api.get(`/assembly/${id}`, {  
            headers:{
            'Authorization': 'Bearer ' +  token  
            }
        }).then(response => {
            setAssembly(response.data);
            
        });

        api.get(`/schedule/${id}`, {  
            headers:{
            'Authorization': 'Bearer ' +  token  
            }
        }).then(response => {
             
            setSchedule(response.data);
            
        });

        
  },[id]);
 
  return (
    <div className={classes.root}>
        
       <Breadcrumbs className={classes.breadcrumbs} aria-label="breadcrumb">
          <Link  to="/home"  className={classes.link}>
            <HomeIcon className={classes.icon} />
            Home
          </Link>
  
          <Typography color="textPrimary"  className={classes.link}>
            <PeopleIcon className={classes.icon} />
            Assembleia
          </Typography>
        </Breadcrumbs>
        <Container maxWidth="lg">
               
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                    <AssignmentIcon className={classes.icon} /> 
                    {assembly.name}
                    </Paper>
                </Grid>
            </Grid>
            {assembly.link &&
            <div>
                <a href={assembly.link} target="_blank" className={classes.link}>
                <ListItem>
                    <ListItemAvatar>
                        <Avatar className={classes.rounded} variant="rounded">
                            <LinkIcon />
                        </Avatar>
                        </ListItemAvatar>
                    <ListItemText primary="Link de trasmissão" />
                </ListItem>
                </a>
            
                
                <Divider variant="inset"   />
            </div>
            }
            <ListItem>
                <ListItemAvatar>
                    <Avatar  className={classes.rounded} variant="rounded">
                        <EventIcon />
                    </Avatar>
                    </ListItemAvatar>
                <ListItemText primary="Data da Reunião" secondary={moment(assembly.date_start).format("DD/MM/YYYY  HH:mm")} />
            </ListItem>
            <Divider variant="inset"/>
            <ListItem>
                <ListItemAvatar>
                    <Avatar className={classes.rounded} variant="rounded">
                        <ScheduleIcon />
                    </Avatar>
                    </ListItemAvatar>
                <ListItemText primary="Prazo de Votação" secondary={moment(assembly.date_end).format("DD/MM/YYYY  HH:mm")} />
            </ListItem>
             
           
        {schedule.map((index) => (
            <Card key={index.id} className={classes.card} variant="outlined">
                <CardContent>
                    
                    <Typography variant="p" component="h3" >
                    {index.name}
                    </Typography>
                    <Divider />
                    
                        
                    <FormControl style={{marginTop:10}} component="fildset">
                    
                        <RadioGroup  aria-label="gender" name="gender" >
                        {index.schedule_response.map((response) => (
                            <FormControlLabel value={value} onChange={(e) => handleChange(e.target.value, index)}
                              key={response.id} 
                              value={String(response.id)} 
                              control={<Radio />} 
                              label={response.name} />     
                            ))}
                        </RadioGroup>
                          
                      </FormControl>
                  
                </CardContent>
            <CardActions className={classes.buttonChat}>
                <Button color="secondary" variant="outlined" size="small">Chat</Button>
            </CardActions>
            </Card>
            )) }
      
      <Button
        variant="outlined"
        color="primary"
        className={classes.button}
        onClick={handleResponse}
         
      >
        Enviar
      </Button>
    </Container>
       
    </div>
  );
}