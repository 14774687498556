import React from "react";
import { Link } from "react-router-dom";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { withStyles } from "@material-ui/core/styles";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import Grid from "@material-ui/core/Grid";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  demo: {
    backgroundColor: theme.palette.background.paper,
  },
}));

const MuiAccordion = withStyles({
  root: {
    border: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(Accordion);

const MuiAccordionSummary = withStyles({
  root: {
    backgroundColor: "rgba(0, 0, 0, .03)",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(AccordionSummary);

const MuiAccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(0),
    display: "contents",
  },
}))(AccordionDetails);

export default function DocumentListItem({ item, year }) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(0);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div style={{ overflow: "auto", marginTop: 10 }}>
      <Grid>
        <div className={classes.demo}>
          {item.map((value, index) => (
            <MuiAccordion
              key={index}
              square
              expanded={expanded === index}
              onChange={handleChange(index)}
            >
              <MuiAccordionSummary
                aria-controls={`${value.slug}d-content`}
                id={`${value.slug}d-header`}
              >
                <Typography>
                  {value.name} {year}
                </Typography>
              </MuiAccordionSummary>
              <MuiAccordionDetails>
                <List dense={true}>
                  {value.document.map((itemDocument, index) => (
                    <Link
                      key={index}
                      to={itemDocument.url}
                      target="_blank"
                      download={itemDocument.archive}
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      <ListItem button>
                        <ListItemIcon>
                          <PictureAsPdfIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary={itemDocument.title}
                          secondary={`Publicado em: ${itemDocument.dt_publication}`}
                        />
                      </ListItem>
                    </Link>
                  ))}
                </List>
              </MuiAccordionDetails>
            </MuiAccordion>
          ))}
        </div>
      </Grid>
    </div>
  );
}
