import React, { useEffect } from "react";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import { makeStyles } from "@material-ui/core/styles";
import { useButtonMenu } from "../../context/HandleButtonHeaderMenu";
import { useAuthorization } from "../../context/Authorization";
import clsx from "clsx";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { mainListItems, secondaryListItems } from "./MenuItem";
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
  },
  drawerPaperClose: {
    overflowX: "hidden",

    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
}));

export default function Menu() {
  const matches = useMediaQuery("(max-width:475px)");
  const { associete } = useAuthorization();
  const classes = useStyles();
  const { openButtonMenu, setOpenButtonMenu } = useButtonMenu();

  useEffect(() => {
    setOpenButtonMenu(true);

    if (matches) {
      setOpenButtonMenu(false);
    }
  }, [matches]);

  const handleDrawerClose = () => {
    setOpenButtonMenu(false);
  };

  return (
    <Drawer
      variant={matches ? "temporary" : "permanent"}
      classes={{
        paper: clsx(
          classes.drawerPaper,
          !openButtonMenu && classes.drawerPaperClose
        ),
      }}
      open={openButtonMenu}
    >
      <div className={classes.toolbarIcon}>
        {matches && (
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        )}
      </div>
      <Divider />
      <List>{mainListItems}</List>
      <Divider />
      {associete && <List>{secondaryListItems}</List>}
    </Drawer>
  );
}
