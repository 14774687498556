import React, { createContext, useState, useContext } from "react";

const HandleButtonHeaderMenuContext = createContext();

export default function HandleButtonHeaderMenu({ children }) {
  const [openButtonMenu, setOpenButtonMenu] = useState(false);

  return (
    <HandleButtonHeaderMenuContext.Provider
      value={{ openButtonMenu, setOpenButtonMenu }}
    >
      {children}
    </HandleButtonHeaderMenuContext.Provider>
  );
}

export function useButtonMenu() {
  const context = useContext(HandleButtonHeaderMenuContext);

  const { openButtonMenu, setOpenButtonMenu } = context;

  return { openButtonMenu, setOpenButtonMenu };
}
