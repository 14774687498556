import React, { createContext, useState, useContext } from "react";

const SimpleSnackBarContext = createContext();

export default function SimpleSnackBarProvider({ children }) {
  const [opensnack, setOpenSnack] = useState(false);
  const [state, setState] = useState({ message: null, open: false });
  return (
    <SimpleSnackBarContext.Provider
      value={{ opensnack, setOpenSnack, state, setState }}
    >
      {children}
    </SimpleSnackBarContext.Provider>
  );
}

export function useSimpleSnackBar() {
  const context = useContext(SimpleSnackBarContext);

  const { opensnack, setOpenSnack, state, setState } = context;

  return { opensnack, setOpenSnack, state, setState };
}
