import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";

const prodConfig = {
  apiKey: "",
  authDomain: "",
  projectId: "",
  storageBucket: "",
  messagingSenderId: "",
  appId: "",
  measurementId: "",
};

//mais recente
const devConfig = {
  apiKey: "AIzaSyD9RvodclgOZOctO3gY8531L-CzAKNG3AE",
  authDomain: "assemarn-ceb13.firebaseapp.com",
  databaseURL: "https://assemarn-ceb13.firebaseio.com",
  projectId: "assemarn-ceb13",
  storageBucket: "assemarn-ceb13.appspot.com",
  messagingSenderId: "577982119259",
  appId: "1:577982119259:web:ea0dab519d2a24b5ad2e7e",
};

const config = devConfig;

if (!firebase.apps.length) {
  firebase.initializeApp(config);
}

//const db = firebase.database();
const auth = firebase.auth();

//adicionar quantos serviços firebases desejar
export { auth };
