import React, { useEffect, useState } from "react";
import apiAssema from "../../services/apiSiteAssema";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Fade from "@material-ui/core/Fade";
import Img from "../../assets/ilustra/Business_PNG.png";
import Header from "../../components/layout/Header";
import Menu from "../../components/layout/Menu";
import Content from "../../components/layout/Page/Content";
import CardNews from "../../components/layout/CardNews";
import UnregisteredAssociate from "../../components/layout/UnregisteredAssociate";
import { useAuthorization } from "../../components/context/Authorization";
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';

import api from "../../services/api";
import { getCookie } from "../../services/cookie";


const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexGrow: 1,
  },
  card: {
    display: "flex",
    padding: 20,
    marginBottom: 10,
  },
}));

export default function Home() {
  const classes = useStyles();
  const token = getCookie("x-token");
  const [item, setItem] = useState([]);
  const [loadingCardNews, setLoadingCardNews] = useState(false);

  const [valor, setValor] = useState('');
  const [mes, setMes] = useState('');
  const [ano, setAno] = useState('');
  const [loadingInvoice, setLoadingInvoice] = useState(false);
  const [successInvocie, setSuccessInvoice] = useState(false);


 
  const { associete, name } = useAuthorization();

  useEffect(() => {
     
    setLoadingCardNews(true);
    apiAssema
      .get("SliderNoticiaWebservice.class.php")
      .then((response) => {
        const { dados } = response.data;
        setItem(dados);
      })
      .catch((error) => {
        alert('Erro interno! Tente novamente');
      })
      .finally((response) => {
        setLoadingCardNews(false);
      });
  }, []);

  useEffect(() => {
    setLoadingInvoice(true)
    api
      .get("invoice", { headers: { "x-client-token": token } })
      .then((response) => {
        const { data, sucesso } = response.data;
        setSuccessInvoice(sucesso)
        setValor(data?.valor)
        setMes(data?.mes)
        setAno(data?.ano)
       
      }).catch(error => {
        //alert('Erro interno! Tente novamente');
      }).finally(() => {
        setLoadingInvoice(false)
      });
  }, []);

  return (
    <div className={classes.root}>
      <Header />
      <Menu />
      <Content>
        {!associete && <UnregisteredAssociate />}
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Fade in={true} timeout={500}>
              <Card className={classes.card}>
                <CardContent style={{ marginRight: "auto" }}>
                  <Typography variant="h4"> Olá, {name}</Typography>
                  <Typography>
                    {" "}
                    Seja bem vindo(a) a área do associado.
                  </Typography>
                </CardContent>

                <img src={Img} alt="some text" width="300" height="200" />
              </Card>
            </Fade>
            
            <Card className={classes.card}>
              
              { !loadingInvoice ? 

                successInvocie ? (
                  <CardContent>
                    <Typography variant="h5"> Desconto de serviços - {mes}/{ano} </Typography>
                    <Typography variant="p"> {valor} </Typography>
                  </CardContent>
                  ) : 
                  <CardContent>
                    <Typography variant="h5"> Nenhum desconto de serviço cadastrado </Typography>
                  </CardContent>
              : 
              <CircularProgress /> }
            </Card>

          </Grid>
          <Card className={classes.card}>
            <CardContent>
              {loadingCardNews ? (
                <CircularProgress />
              ) : (
                <Fade in={true} timeout={500}>
                  <CardNews item={item} />
                </Fade>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Content>
    </div>
  );
}
