import React, { useState, useEffect } from "react";
import api from "../../../../services/api";
import { validatePassword } from "../../../../utils/validate";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Input from "@material-ui/core/Input";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import SaveIcon from "@material-ui/icons/Save";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CircularProgress from "@material-ui/core/CircularProgress";

import { getCookie } from "../../../../services/cookie";

import { useAuthorization } from "../../../context/Authorization";

import IconButton from "@material-ui/core/IconButton";
import InputLabel from "@material-ui/core/InputLabel";
import Fade from "@material-ui/core/Fade";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

import { useSnackbar } from "../../SnackbarProvider";
import { useHistory } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginBottom: 20,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "rigth",
  },
  grid: {
    marginTop: 10,
    marginBottom: 20,
  },
  form: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
    display: "flex",
    flexWrap: "wrap",
    marginTop: 20,
  },
  field: {
    display: "grid",
  },
  button: {
    marginRight: 8,
    marginTop: 20,
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

export default function ProfileForm() {
  const classes = useStyles();
  const theme = useTheme();
  const token = getCookie("x-token");

  const history = useHistory();
  const snackbar = useSnackbar();

  const [loading, setLoading] = useState(false);

  const backToDashboard = () => {
    history.push("/home");
  };

  const [values, setValues] = React.useState({
    password: "",
    verifyPassword: "",
    showPassword: false,
    showVerifyPassword: false,
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleClickShowVerifyPassword = () => {
    setValues({ ...values, showVerifyPassword: !values.showVerifyPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleChangePassword = async () => {
    setLoading(true);
    const data = {
      password: values.password,
      verifyPassword: values.verifyPassword,
    };
    api
      .post("auth/password/update", data, {
        headers: {
          "x-client-token": token,
        },
      })
      .then((response) => {
        snackbar.showSuccessMessage("Senha alterada com sucesso!");
        history.push("/home");
      })
      .catch((error) => {
        const message =
          error.response.data.erros?.length > 0
            ? error.response.data.erros[0]
            : error.response.data.mensagem ??
              "Erro ao editar senha, tente novamente!";

        snackbar.showFailureMessage(message);
        
      })
      .finally((response) => {
        setLoading(false);
      });
  };
  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid className={classes.grid} item xs={12}>
          <Fade in={true} timeout={500}>
            <Paper className={classes.paper}>
              <Typography
                component="strong"
                variant="h3"
                color="inherit"
                noWrap
              >
                Alterar Senha
              </Typography>
              <div className={classes.form}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={6}>
                    <InputLabel htmlFor="standard-adornment-password">
                      Nova senha
                    </InputLabel>
                    <Input
                      error={validatePassword(values.password)}
                      helperText={validatePassword(values.password)}
                      id="standard-adornment-password"
                      type={values.showPassword ? "text" : "password"}
                      value={values.password}
                      onChange={handleChange("password")}
                      fullWidth
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {values.showPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    <span style={{ color: "#E96F6F" }}>
                      {validatePassword(values.password)}
                    </span>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <InputLabel htmlFor="standard-adornment-password">
                      Confirmar Senha
                    </InputLabel>
                    <Input
                      error={
                        validatePassword(values.password) !== "undefined" &&
                        values.verifyPassword !== values.password
                      }
                      id="standard-adornment-password"
                      type={values.showVerifyPassword ? "text" : "password"}
                      value={values.verifyPassword}
                      onChange={handleChange("verifyPassword")}
                      fullWidth
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowVerifyPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {values.showVerifyPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    <span style={{ color: "#E96F6F" }}>
                      {validatePassword(values.password) !== "undefined" &&
                        values.verifyPassword !== values.password &&
                        "Senha não correspodem"}
                    </span>
                  </Grid>
                </Grid>

                <Button
                  color="primary"
                  variant="contained"
                  className={classes.button}
                  startIcon={<SaveIcon />}
                  onClick={handleChangePassword}
                  disabled={
                    typeof validatePassword(values.password) !== "undefined" ||
                    values.password !== values.verifyPassword ||
                    loading
                  }
                >
                  Salvar
                  {loading && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </Button>
                <Button
                  variant="contained"
                  className={classes.button}
                  startIcon={<ArrowBackIcon />}
                  onClick={backToDashboard}
                >
                  Voltar
                </Button>
              </div>
            </Paper>
          </Fade>
        </Grid>
      </Grid>
    </div>
  );
}
