import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { compareAsc, format } from 'date-fns'
 
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PhoneIcon from '@material-ui/icons/SkipNext';
import FavoriteIcon from '@material-ui/icons/Done';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import HomeIcon from '@material-ui/icons/Home';
import PeopleIcon from '@material-ui/icons/People';

import ListFolder from '../../components/layout/ListFolder';
import Skeleton from '../../components/layout/Skeleton';

import api from '../../services/api';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
     
  },
  link: {
    display: 'flex',
    textDecoration: 'none',
    color:"inherit",
    fontSize:14
  },
  breadcrumbs:{
     marginBottom:15,
     
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  },
}));

export default function ScrollableTabsButtonForce() {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const token = localStorage.getItem('token');

  const [ skeletonOpen, setSkeletonOpen ]  = useState(false);

  const [ statusEncerado, setStatusEncerrado] = useState([]);
  const [ statusAberto, setStatusAberto] = useState([]);

  useEffect(() => {

    
    api.get('assembly/list',{
  
      headers:{
        'Authorization': 'Bearer ' +  token  
      },
      params:{
        'status':'Encerrado'
      }  

    }).then(response => {

      setStatusEncerrado(response.data);

    });

    api.get('assembly/list',{
  
      headers:{
        'Authorization': 'Bearer ' +  token  
      },
      params:{
        'status':'Aberto'
      }  

    }).then(response => {

      setStatusAberto(response.data);
      setSkeletonOpen(true);

    });

    

  },[token]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
       <Breadcrumbs className={classes.breadcrumbs} aria-label="breadcrumb">
          <Link  to="/home"  className={classes.link}>
            <HomeIcon className={classes.icon} />
            Home
          </Link>
  
          <Typography color="textPrimary"  className={classes.link}>
            <PeopleIcon className={classes.icon} />
            Assembleia
          </Typography>
        </Breadcrumbs>

      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="on"
          indicatorColor="primary"
          textColor="primary"
          aria-label="scrollable force tabs example"
        >
          <Tab label="Próximas Assembleias" icon={<PhoneIcon />} {...a11yProps(0)} />
          <Tab label="Assembleias Passadas" icon={<FavoriteIcon />} {...a11yProps(1)} />
           
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
      { skeletonOpen === false &&
        <Skeleton />
      }
      

      {statusAberto.map((index) => (
          <Link key={index.id} to={`/assembleia/${index.id}`}  style={{ textDecoration: 'none',color:"inherit" }} > 
            <ListFolder title={index.name}  status={index.status} dateTime={ format(new Date (index.date_end), 'dd/MM/yyyy H:m') } />
          </Link>
        ) )}
      </TabPanel>
      <TabPanel value={value} index={1}>

        {statusEncerado.map((index) => (
          <Link key={index.id} to={`/assembleia/${index.id}`}  style={{ textDecoration: 'none',color:"inherit" }} > 
            <ListFolder title={index.name}  status={index.status} dateTime={ format(new Date (index.date_end), 'dd/MM/yyyy H:m') } />
          </Link>
        ) )}

      </TabPanel>
       
    </div>
  );
}