import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { useAuthorization } from "./components/context/Authorization";

import Login from "./pages/Login";
import Register from "./pages/Register";

import Home from "./pages/Home";
import ChangePassword from "./pages/ChangePassword";
import Documents from "./pages/Documents";
import Assembly from "./pages/Assembly";
import AssemblyDetail from "./pages/AssemblyDetail";
import Profile from "./pages/Profile";
import ForgotPassword from "./pages/ForgotPassword";
import Help from "./pages/Help";
import UpdateAssocieteRegister from "./pages/UpdateAssocieteRegister";
import Agreement from "./pages/Agreement";
import WalletAssociate from "./pages/WalletAssociate";

function AuthRoute({ component: Component, ...rest }) {
  const { authorization, associete } = useAuthorization();

  if (authorization !== true) {
    return <Redirect to="/" />;
  }

  return <Route {...rest} render={(props) => <Component {...props} />} />;
}

export default function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={Login} />
        <Route path="/sign-up" exact component={Register} />
        <Route path="/forgot-password" exact component={ForgotPassword} />
        <Route path="/help" exact component={Help} />

        <AuthRoute path="/home" component={Home} />
        <AuthRoute path="/wallet" component={WalletAssociate} />
        <AuthRoute path="/profile" component={Profile} />
        <AuthRoute path="/documents" component={Documents} />
        <AuthRoute path="/agreement" component={Agreement} />
        <AuthRoute
          path="/update-associete"
          component={UpdateAssocieteRegister}
        />
        <AuthRoute path="/change-password" component={ChangePassword} />
        <AuthRoute path="/assembleia" exact component={Assembly} />
        <AuthRoute path="/assembleia/:id" component={AssemblyDetail} />
      </Switch>
    </BrowserRouter>
  );
}
