import React, { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Fade from "@material-ui/core/Fade";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginBottom: 20,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "rigth",
  },
  grid: {
    marginTop: 10,
    marginBottom: 20,
  },
  button: {
    marginTop: 10,
  },
}));

export default function UnregisteredAssociate() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Grid container spacing={3}>
        <Grid className={classes.grid} item xs={12}>
          <Fade in={true} timeout={500}>
            <Paper className={classes.paper}>
              <Typography
                component="b"
                variant="h4"
                gutterBottom
                color="primary"
              >
                Torne-se um associado!
              </Typography>
              <Typography
                style={{ marginTop: 10 }}
                component="p"
                variant="p"
                gutterBottom
                color="primary"
              >
                Faça parte do nosso quadro de associado e garanta descontos em
                serviços e a garantia na luta pelas melhores condições da
                classe!
              </Typography>
              <Button
                className={classes.button}
                variant="outlined"
                color="primary"
                onClick={handleClickOpen}
              >
                Associe-se já!
              </Button>
            </Paper>
          </Fade>
        </Grid>
      </Grid>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>
          <Typography component="b" variant="h4" gutterBottom color="primary">
            Entre em contato conosco!
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography
              component="h1"
              variant="subtitle1"
              gutterBottom
              color="primary"
            >
              Telefone: 3234-9290
            </Typography>
            <Typography
              component="h1"
              variant="subtitle1"
              gutterBottom
              color="primary"
            >
              Email: assemarn@assemarn.com.br
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            variant="contained"
            color="primary"
            autoFocus
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
