import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import DashboardIcon from "@material-ui/icons/Dashboard";
import BarChartIcon from "@material-ui/icons/BarChart";
import LayersIcon from "@material-ui/icons/Layers";
import Tooltip from "@material-ui/core/Tooltip";
import MenuItemCollapse from "./MenuItemCollapse";

import { Link } from "react-router-dom";

export const mainListItems = (
  <div>
    <Link to="/home" style={{ textDecoration: "none", color: "inherit" }}>
      <Tooltip title="Dashboard" placement="right">
        <ListItem button>
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItem>
      </Tooltip>
    </Link>

    <Link to="/documents" style={{ textDecoration: "none", color: "inherit" }}>
      <Tooltip title="Documentos" placement="right">
        <ListItem button>
          <ListItemIcon>
            <BarChartIcon />
          </ListItemIcon>
          <ListItemText primary="Documentos" />
        </ListItem>
      </Tooltip>
    </Link>

    <Link to="/agreement" style={{ textDecoration: "none", color: "inherit" }}>
      <ListItem button>
        <ListItemIcon>
          <LayersIcon />
        </ListItemIcon>
        <ListItemText primary="Cônvenios" />
      </ListItem>
    </Link>
  </div>
);

export const secondaryListItems = (
  <div>
    <MenuItemCollapse />
  </div>
);
