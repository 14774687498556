import axios from "axios";

const apiSiteAssema = axios.create({
  baseURL: process.env.REACT_APP_API_SITE,
  headers: {
    "Content-Type": "text/html",
  },
});

export default apiSiteAssema;
