import React from "react";
import { useHistory, Link } from "react-router-dom";
import { useAuthorization } from "../../context/Authorization";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

import IconButton from "@material-ui/core/IconButton";

import Badge from "@material-ui/core/Badge";
import Menu from "@material-ui/core/Menu";
import Divider from "@material-ui/core/Divider";
import NotificationsIcon from "@material-ui/icons/Notifications";
import { makeStyles, useTheme } from "@material-ui/core/styles";

import { parseISO, format, formatRelative, formatDistance } from "date-fns";
//import pt from "date-fns/locales/pt";
import { pt } from "date-fns/locale";

const drawerWidth = 240;
const ITEM_HEIGHT = 40;

const useStyles = makeStyles((theme) => ({
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  iconTitle: {
    marginRight: 5,
    fontSize: 20,
  },
}));

export default function Header({ item }) {
  const classes = useStyles();
  const history = useHistory();

  const dateNow = new Date();

  const formatDateDistance = (date) => {
    const secondDate = parseISO(date);
    return formatDistance(dateNow, secondDate, { locale: pt });
  };
  const [anchorElComunicate, setAnchorElComunicate] = React.useState(null);
  const openComunicate = Boolean(anchorElComunicate);

  const handleClickComunicate = (event) => {
    setAnchorElComunicate(event.currentTarget);
  };

  const handleCloseComunicate = () => {
    setAnchorElComunicate(null);
  };

  return (
    <React.Fragment>
      <IconButton color="inherit" onClick={handleClickComunicate}>
        <Badge badgeContent={item.length} color="secondary">
          <NotificationsIcon />
        </Badge>
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorElComunicate}
        keepMounted
        open={openComunicate}
        onClose={handleCloseComunicate}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "50ch",
          },
        }}
      >
        <List dense={true} onClick={handleCloseComunicate}>
          {item.length > 0 ? (
            item.map((value, index) => (
              <div>
                <ListItem>
                  <ListItemText
                    primary={value.comunicate}
                    secondary={`${
                      value.title
                    } - Publicado há ${formatDateDistance(
                      value.dt_publication
                    )}`}
                  />
                </ListItem>
                {item.length > 0 && <Divider />}
              </div>
            ))
          ) : (
            <ListItem>
              <ListItemText primary={"Sem nenhum comunicado recente"} />
            </ListItem>
          )}
        </List>
      </Menu>
    </React.Fragment>
  );
}
