//import CPF from "cpf";
import CPF from "cpf";

export const required = (value) => (value ? undefined : "Campo obrigatório");

export const message = (value) => {
  let re = /^.{6,}$/;
  return re.test(String(value).toLowerCase()) && value
    ? undefined
    : "Sua mensagem deve conter ao menos 6 caracteres";
};

export const validateEmail = (value) => {
  let re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(value).toLowerCase()) ? undefined : "Email inválido";
};

export const phone = (value) => {
  let re = /^\(\d{2}\) \d{4,5}\-\d{4}$/;
  return re.test(value) ? undefined : "Telefone inválido";
};

export const cep = (value) => {
  let re = /^\d{5}\-\d{3}$/;
  return re.test(value) ? undefined : "CEP inválido";
};

export const card = (value) => {
  let re = /^\d{4}\ \d{4} \d{4} \d{4}$/;
  return re.test(value) ? undefined : "Cartão inválido";
};

export const cvv = (value) => {
  let re = /^\d{3}$/;
  return re.test(value) ? undefined : "Código inválido";
};
export const CPFValidate = (value) => CPF.isValid(value);

export const validatePassword = (value, required = true) => {
  if (!required) {
    return undefined;
  }
  return value && value.length >= 8
    ? undefined
    : "Senha maior que 8 caracteres";
};

export const composeValidators =
  (...validators) =>
  (value) =>
    validators.reduce(
      (error, validator) => error || validator(value),
      undefined
    );

export const mustBeNumber = (value) =>
  isNaN(value) ? "Deve ser um número" : undefined;

export const minValue = (min) => (value) =>
  isNaN(value) || value >= min ? undefined : `Deve ser maior que ${min}`;
