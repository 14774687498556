import React, { useState, useEffect } from "react";
import api from "../../../../services/api";

import PropTypes from "prop-types";
import { removeCPFMask } from "../../../../utils/string";
import MaskedInput from "react-text-mask";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import SaveIcon from "@material-ui/icons/Save";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CircularProgress from "@material-ui/core/CircularProgress";
import Fade from "@material-ui/core/Fade";
import { getCookie } from "../../../../services/cookie";

import { useAuthorization } from "../../../context/Authorization";

import { validateEmail } from "../../../../utils/validate";

import { useSnackbar } from "../../SnackbarProvider";
import { useHistory, Router } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginBottom: 20,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "rigth",
  },
  grid: {
    marginTop: 10,
    marginBottom: 20,
  },
  form: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
    display: "flex",
    flexWrap: "wrap",
    marginTop: 20,
  },
  field: {
    display: "grid",
  },
  button: {
    marginLeft: 8,
    marginTop: 10,
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        /[0-9]/,
        /\d/,
        /\d/,
        ".",
        /\d/,
        /\d/,
        /\d/,
        ".",
        /\d/,
        /\d/,
        /\d/,
        "-",
        /\d/,
        /\d/,
      ]}
      placeholderChar={"\u2000"}
    />
  );
}

TextMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

export default function ProfileForm() {
  const classes = useStyles();
  const theme = useTheme();
  const token = getCookie("x-token");

  const history = useHistory();
  const snackbar = useSnackbar();

  const { name, lastname, email, cpf, setName } = useAuthorization();

  const [nameInput, setNameInput] = useState(name);
  const [lastnameInput, setLastnameInput] = useState(lastname);
  const [cpfInput, setCpfInput] = useState(cpf);
  const [emailInput, setEmailInput] = useState(email);
  const [contatoInput, setContatoInput] = useState(name);
  const [loading, setLoading] = useState(false);

  const backToDashboard = () => {
    history.push("/home");
  };

  const handleEdit = async () => {
    setLoading(true);
    const data = {
      name: nameInput,
      lastname: lastnameInput,
      cpf: removeCPFMask(cpfInput),
      email: emailInput,
    };

    await api
      .post("auth/profile/update", data, {
        headers: {
          "x-client-token": token,
        },
      })
      .then((response) => {
        snackbar.showSuccessMessage("Editado com sucesso!");
        setName(nameInput);
        setTimeout(() => {
          window.location.assign("/home");
        }, 1000);
      })
      .catch((error) => {
        const message =
          error.response.data.erros?.length > 0
            ? error.response.data.erros[0]
            : error.response.data.mensagem ??
              "Erro ao editar perfil, tente novamente!";

        snackbar.showFailureMessage(message);
      })
      .finally((response) => {
        setLoading(false);
      });
  };
  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid className={classes.grid} item xs={12}>
          <Fade in={true} timeout={500}>
            <Paper className={classes.paper}>
              <Typography
                component="strong"
                variant="h3"
                color="inherit"
                noWrap
              >
                Perfil
              </Typography>
              <div className={classes.form}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      error={!nameInput}
                      helperText={!nameInput && "Nome é obrigatório"}
                      name="firstName"
                      variant="outlined"
                      required
                      fullWidth
                      id="firstName"
                      label="Nome"
                      value={nameInput}
                      onChange={(event) => setNameInput(event.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      error={!lastnameInput}
                      helperText={!lastnameInput && "Sobrenome é obrigatório"}
                      variant="outlined"
                      required
                      fullWidth
                      id="lastName"
                      label="Sobrenome"
                      name="lastName"
                      autoComplete="lname"
                      value={lastnameInput}
                      onChange={(event) => setLastnameInput(event.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      error={!cpfInput}
                      helperText={!cpfInput && "CPF é obrigatório"}
                      variant="outlined"
                      required
                      fullWidth
                      id="cpf"
                      label="CPF"
                      name="cpf"
                      autoComplete="cpf"
                      value={cpfInput}
                      onChange={(event) => setCpfInput(event.target.value)}
                      InputProps={{
                        inputComponent: TextMaskCustom,
                      }}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      id="contact"
                      label="Contato"
                      name="contact"
                      autoComplete="contact"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      error={!emailInput || validateEmail(emailInput)}
                      helperText={
                        (!emailInput && "E-mail é obrigatório") ||
                        validateEmail(emailInput)
                      }
                      variant="outlined"
                      required
                      fullWidth
                      id="email"
                      label="E-mail"
                      name="email"
                      autoComplete="email"
                      value={emailInput}
                      onChange={(event) => setEmailInput(event.target.value)}
                    />
                  </Grid>
                </Grid>

                <Button
                  color="primary"
                  variant="contained"
                  className={classes.button}
                  startIcon={<SaveIcon />}
                  onClick={handleEdit}
                  disabled={loading}
                >
                  Salvar
                  {loading && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </Button>
                <Button
                  variant="contained"
                  className={classes.button}
                  startIcon={<ArrowBackIcon />}
                  onClick={backToDashboard}
                >
                  Voltar
                </Button>
              </div>
            </Paper>
          </Fade>
        </Grid>
      </Grid>
    </div>
  );
}
