import React, { useState, useEffect } from "react";
import api from "../../../../services/api";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import CircularProgress from "@material-ui/core/CircularProgress";
import Fade from "@material-ui/core/Fade";

import DocumentListItem from "../../DocumentListItem";
import { getCookie } from "../../../../services/cookie";

import { useAuthorization } from "../../../context/Authorization";

import { useSnackbar } from "../../SnackbarProvider";
import { useHistory } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginBottom: 20,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "rigth",
  },
  grid: {
    marginTop: 10,
    marginBottom: 20,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  form: {
    display: "grid",
    margin: theme.spacing(1),
  },
  loading: {
    marginTop: 10,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export default function ContentsDocuments() {
  const classes = useStyles();
  const token = getCookie("x-token");
  const [itemDocument, setItemDocument] = useState([]);
  const [arrayYear, setArrayYear] = useState([]);
  const [search, setSearch] = useState("");
  const [year, setYear] = useState(2022);
  const [loading, setLoading] = useState(false);
  const [disabledYear, setDisabledYear] = useState(false);
  const snackbar = useSnackbar();
  const history = useHistory();
  const handleChange = (event) => {
    setYear(event.target.value);
  };

  const { associete } = useAuthorization();

  useEffect(() => {
    if (!associete) {
      snackbar.showFailureMessage("Úsuario não autorizado!");
      history.push("/home");
    }

    setDisabledYear(true);
    api
      .get("document/year", {
        params: {
          search,
          year,
        },
        headers: {
          "x-client-token": token,
        },
      })
      .then((response) => {
        const { data } = response.data;
        const year = Object.keys(data);
        setArrayYear(year);
      })
      .catch((error) => {
        snackbar.showFailureMessage(
          "Error ao carregar os dados, recarrege a pagina!"
        );
      })
      .finally((response) => {
        setDisabledYear(false);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    api
      .get("document", {
        params: {
          search,
          year,
        },
        headers: {
          "x-client-token": token,
        },
      })
      .then((response) => {
        const { data } = response.data;
        const filterData = data.filter((value) => {
          return value.document.length > 0;
        });
        setItemDocument(filterData);
      })
      .catch((error) => {
        snackbar.showFailureMessage(
          "Error ao carregar os dados, recarrege a pagina!"
        );
        
      })
      .finally((response) => {
        setLoading(false);
      });
  }, [search, year]);

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid className={classes.grid} item xs={12}>
          <Fade in={true} timeout={500}>
            <Paper className={classes.paper}>
              <Typography
                component="strong"
                variant="h3"
                color="inherit"
                noWrap
              >
                Documentos
              </Typography>
              <Accordion style={{ marginTop: 15 }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.heading}>Filtros</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div className={classes.form}>
                    <InputLabel id="demo-simple-select-label">Ano</InputLabel>
                    <Select
                      disabled={disabledYear}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={year}
                      onChange={handleChange}
                    >
                      {arrayYear.map((value) => (
                        <MenuItem key={value} value={value}>
                          {value}
                        </MenuItem>
                      ))}
                    </Select>

                    <TextField
                      id="search"
                      label="Search"
                      name="search"
                      autoComplete="search"
                      autoFocus
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  </div>
                </AccordionDetails>
              </Accordion>

              {loading ? (
                <div className={classes.loading}>
                  <CircularProgress />
                </div>
              ) : itemDocument.length > 0 ? (
                <DocumentListItem year={year} item={itemDocument} />
              ) : (
                <Typography>Sem dados!</Typography>
              )}
            </Paper>
          </Fade>
        </Grid>
      </Grid>
    </div>
  );
}
