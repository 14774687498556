import React, { useEffect, useState } from "react";
import apiAssema from "../../services/apiSiteAssema";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActionArea from "@material-ui/core/CardActionArea";
import Avatar from "@material-ui/core/Avatar";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import LocationOnIcon from "@material-ui/icons/LocationOn";

import Header from "../../components/layout/Header";
import Menu from "../../components/layout/Menu";
import Content from "../../components/layout/Page/Content";
import CardItemUpdateAssociete from "../../components/layout/CardItemUpdateAssociete";
import TabsUpdateAssociete from "../../components/layout/TabsUpdateAssociete";

import { useAuthorization } from "../../components/context/Authorization";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexGrow: 1,
  },
  card: {
    display: "flex",
    padding: 20,
    marginBottom: 10,
  },
  paper: {
    color: theme.palette.text.secondary,
  },
  grid: {
    marginTop: 20,
    width: "100%",
  },
  avatarActive: {
    color: "#fff",
    backgroundColor: "#0080007a",
  },
}));

export default function UpdateAssocieteRegister() {
  const classes = useStyles();

  useEffect(() => {}, []);

  const data = [
    {
      title: "Pessoal",
      icon: <AccountCircleIcon />,
      active: true,
      link: "update-associete/personal",
    },
    {
      title: "Endereço",
      icon: <LocationOnIcon />,
      active: true,
      link: "",
    },
    {
      title: "Dependente",
      icon: "",
      active: false,
      link: "",
    },
  ];

  return (
    <div className={classes.root}>
      <Header />
      <Menu />
      <Content>
        <Typography variant="h3" component="p" color="primary">
          Atualização Cadastral
        </Typography>

        <TabsUpdateAssociete />
      </Content>
    </div>
  );
}
