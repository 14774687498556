import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { makeStyles } from "@material-ui/core/styles";

import Header from "../../components/layout/Header";
import Menu from "../../components/layout/Menu";
import Content from "../../components/layout/Page/Content";
import ContentAgreement from "../../components/layout/Page/Agreement";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
}));

export default function Agreement() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Header />
      <Menu />
      <Content>
        <Breadcrumbs aria-label="breadcrumb">
          <Link style={{ textDecoration: "none", color: "inherit" }} to="/home">
            Dashboard
          </Link>
          <Typography color="textPrimary">Convênios</Typography>
        </Breadcrumbs>
        <ContentAgreement />
      </Content>
    </div>
  );
}
